import React, { useState, useEffect } from 'react';
import { Navbar, Container, Row, Col, Nav, Modal } from 'react-bootstrap'
import './NavbarMobile.css';
import ArrowRight from '../../assets/images/icon-arrow.png'
import TdaLogo from '../../assets/images/tda-logo.png'
import IconList from '../../assets/images/icon-list.png'
import IconSearch from '../../assets/images/icon-search.png'
import IconClose from '../../assets/images/icon-close.png'
import IconNotification from '../../assets/images/icon-notification.png'
import { FormField } from '../FormField/FormField'
import { useLocation } from 'react-router-dom'
import { CarCard } from '../CarCard/CarCard'

import ReactGA from 'react-ga'
import { APIGET } from "../../helper/api"
import { CAR_URL, BASE_URL, ACCESS_TOKEN, BRAND_URL, MERCHANDISE_URL } from "../../assets/config/api"

function NavbarMobile() {
    const location = useLocation().pathname.split("/")[1]
    const slugForIconBlack = ["events", "news","magazine", "wheels", "membership", 
    "login", "register", "forgot-password", "car", "my-profile", "my-garage"]
    const [navSearchText, setNavSearchText] = useState(false);

    const [navPrimary, setNavPrimary] = useState(false)
    const [navSecondaryCars, setNavSecondaryCars] = useState(false)
    const [navSecondaryProducts, setNavSecondaryProducts] = useState(false)
    const [navSecondaryProductsDetail, setNavSecondaryProductsDetail] = useState(false)
    const [navSecondaryDiscover, setNavSecondaryDiscover] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [cars, setCar] = useState()
    const [detailProduct, setDetailProduct] = useState([])
    
    const [textSearch, setTextSearch] = useState({
        form: {
            query: {
                value: '',
            },
        },
    })
    
    let param = {
        query: "",
        limit: 50,
        offset: 0,
        token: ACCESS_TOKEN,
    }

    const fetchData = async () => {
        param.query = textSearch.form.query.value
        const res = await APIGET(CAR_URL, param)
        setCar(res)
    }

    const setHandleValueChange = (event) => {
        let copyForm = textSearch
        copyForm["form"][event.target.name]["value"] = event.target.value
        setTextSearch(copyForm)

        if (event.key === 'Enter') {
            fetchData()
        }
    }

    const clickNavSearchText = () => {
        setNavSearchText(!navSearchText)
    }

    const clickNavPrimary = () => {
        setNavPrimary(!navPrimary)
    }

    const handleIsLogin = () => {
        setIsLogin(!isLogin)
    }

    const clickNavCars = () => {
        setNavSecondaryCars(!navSecondaryCars)
    }
    
    const clickNavProducts = () => {
        setNavSecondaryProducts(!navSecondaryProducts)
    }

    const clickNavProductsDetail = (jsonData) => {
        setDetailProduct(jsonData);
        setNavSecondaryProductsDetail(!navSecondaryProductsDetail)
    }
    
    const clickNavDiscover = () => {
        setNavSecondaryDiscover(!navSecondaryDiscover)
    }

    const [y, setY] = useState(window.scrollY);
    const handleNavigation = 
        e => {
        setY(window.scrollY)
    }
    const [responseBrand, setResponseBrand] = useState([])
    const [responseProducts, setResponseProducts] = useState([])
    let param_brand = {
        token: ACCESS_TOKEN,
    }
    useEffect(() => {
        const fetchDataProducts = async () => {
            const res = await APIGET(MERCHANDISE_URL, param_brand)  
            console.log('haha');
            console.log(res);
            
            setResponseProducts(res)
        }
        fetchDataProducts()
        const fetchDataBrand = async () => {
            const res = await APIGET(BRAND_URL, param_brand)
            setResponseBrand(res)
        }
        fetchDataBrand()
        window.addEventListener("scroll", handleNavigation)
        
        const TRACKING_ID = "UA-117747563-2"
        const path = window.location.pathname
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(path)
    }, []);


    return (
        <>
        <Navbar bg="light" expand="lg" className={navPrimary === false ? "tda-display-none" : ""}>
            <Container fluid className="tda-navbar">
                <Row className="tda-navbar-header">
                    <Col lg={6} xs={6} className="tda-navbar-item">
                        <Navbar.Brand href="/" className="tda-navbar-home" >
                            <img src={TdaLogo} alt="arrow"></img>
                        </Navbar.Brand>
                    </Col>
                    <Col lg={6} xs={6} className="tda-navbar-item justify-content-end">
                        <div className='position-relative'>
                            <img className={slugForIconBlack.includes(location) || y !== 0 ? "icon-black icon-notification" : "icon-white icon-notification"} src={IconNotification} alt="l"></img>
                            <div className={
                                    localStorage.getItem("notifications")  ?
                                    localStorage.getItem("notifications") !== '' ? "d-none" : "bullet-notification position-absolute rounded-circle" : "d-none" }></div>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" >
                            <i className="bi bi-x tda-navbar-close" onClick={clickNavPrimary}></i>
                        </Navbar.Toggle>
                    </Col>
                </Row>
                <Container id="basic-navbar-nav" className={navPrimary === false ? "tda-display-none" : ""}>
                    <Row>
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav>
                                    <Nav.Link className={ localStorage.getItem("session_key") ? "tda-navbar-collapse-item tda-navbar-collapse-item-first-login" : "display-none"} href="#" >
                                        <div className="tda-navbar-item-with-arrow tda-navbar-mobile-is-login" onClick={handleIsLogin}>
                                            <div>
                                                <div className="tda-navbar-mobile-login-complete-photo">
                                                {
                                                    localStorage.getItem("first_name") ?
                                                    localStorage.getItem("first_name").charAt(0).toUpperCase()
                                                    :
                                                    ""
                                                }
                                                </div>
                                                <p>
                                                {
                                                    localStorage.getItem("first_name") ?
                                                    localStorage.getItem("first_name")
                                                    :
                                                    ""
                                                }
                                                </p>
                                            </div>
                                            <img src={ArrowRight} alt="arrow"></img>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link  className={ !localStorage.getItem("session_key") ? "tda-navbar-collapse-item tda-navbar-collapse-item-first" : "display-none"} href="/login"><p>LOGIN / REGISTER</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="/"><p>HOME</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="#">
                                        <div className="tda-navbar-item-with-arrow" onClick={clickNavCars}>
                                            <p>CARS</p>
                                            <img src={ArrowRight} alt="arrow"></img>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="#">
                                        <div className="tda-navbar-item-with-arrow" onClick={clickNavProducts}>
                                            <p>PRODUCTS</p>
                                            <img src={ArrowRight} alt="arrow"></img>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="service-center"><p>TDA SERVICE CENTER</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item">
                                        <div className="tda-navbar-item-with-arrow" onClick={clickNavDiscover}>
                                            <p>DISCOVER</p>
                                            <img src={ArrowRight} alt="arrow"></img>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="/wheels"><p>WHEELS</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item" href="/membership"><p>MEMBERSHIP</p></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container id="basic-navbar-nav" className={navSecondaryCars === false ? "tda-display-none tda-navbar-sub" : "tda-navbar-sub"}>
                    <Row className="tda-navbar-collapse">
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav className="tda-navbar-collapse">
                                    <Nav.Link className="tda-navbar-collapse-item-detail-back" id="tda-navbar-collapse-item-detail-back" href="#home">
                                        <div className="tda-navbar-item-with-arrow-back" onClick={clickNavCars}>
                                            <img src={ArrowRight} alt="arrow"></img>
                                            <p>BACK</p>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="#home"><p className="text-bold">AUTHORIZED DEALER</p></Nav.Link>
                                    <div className="row tda-navbar-collapse-grid">
                                        {/* {
                                            responseBrand ? 
                                            responseBrand.map((brand, index) => {
                                                return(
                                                    <div className="col-4 tda-navbar-collapse-grid-card">
                                                        <a href={"/brand/"+brand.title}>
                                                            <img src={BASE_URL+brand.logo} alt="."></img>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                            :
                                            ""
                                        } */}
                                    </div>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/new-car"><p>NEW CARS</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/used-car"><p>USED CARS</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/special-request"><p>SPECIAL REQUEST</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/sell"><p>SELL YOUR CAR</p></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container id="basic-navbar-nav" className={navSecondaryProducts === false ? "tda-display-none tda-navbar-sub" : "tda-navbar-sub"}>
                    <Row className="tda-navbar-collapse">
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav className="tda-navbar-collapse">
                                    <Nav.Link className="tda-navbar-collapse-item-detail-back" id="tda-navbar-collapse-item-detail-back" href="#">
                                        <div className="tda-navbar-item-with-arrow-back" onClick={clickNavProducts}>
                                            <img src={ArrowRight} alt="arrow"></img>
                                            <p>BACK</p>
                                        </div>
                                    </Nav.Link>
                                    {
                                        responseProducts ? 
                                        responseProducts.map((product, index) => {
                                            return(
                                                <Nav.Link key={index} className="tda-navbar-collapse-item-detail" onClick={() => clickNavProductsDetail(product.merchandises)}>
                                                    <p className="text-bold">{product.name.toUpperCase()}</p>
                                                </Nav.Link>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container id="basic-navbar-nav" className={navSecondaryProductsDetail === false ? "tda-display-none tda-navbar-sub" : "tda-navbar-sub"}>
                    <Row className="tda-navbar-collapse">
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav className="tda-navbar-collapse">
                                    <Nav.Link className="tda-navbar-collapse-item-detail-back" id="tda-navbar-collapse-item-detail-back" href="#">
                                        <div className="tda-navbar-item-with-arrow-back" onClick={() => clickNavProductsDetail([])}>
                                            <img src={ArrowRight} alt="arrow"></img>
                                            <p>BACK</p>
                                        </div>
                                    </Nav.Link>
                                    {
                                        detailProduct ? 
                                        detailProduct.map((dp, index) => {
                                            return(
                                                <Nav.Link key={index} className="tda-navbar-collapse-item-detail" href={dp.url} target="_blank">
                                                    <p className="text-bold">{dp.title.toUpperCase()}</p>
                                                </Nav.Link>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container id="basic-navbar-nav" className={navSecondaryDiscover === false ? "tda-display-none tda-navbar-sub" : "tda-navbar-sub"}>
                    <Row className="tda-navbar-collapse">
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav className="tda-navbar-collapse">
                                    <Nav.Link className="tda-navbar-collapse-item-detail-back" id="tda-navbar-collapse-item-detail-back" href="#">
                                        <div className="tda-navbar-item-with-arrow-back" onClick={clickNavDiscover}>
                                            <img src={ArrowRight} alt="arrow"></img>
                                            <p>BACK</p>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/news"><p>NEWS</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/events"><p>EVENTS</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/magazine"><p>MAGAZINE</p></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container id="basic-navbar-nav" className={isLogin === false ? "tda-display-none tda-navbar-sub" : "tda-navbar-sub"}>
                    <Row className="tda-navbar-collapse">
                        <Col xs={12}>
                            <Navbar.Collapse className="tda-navbar-collapse show">
                                <Nav className="tda-navbar-collapse">
                                    <Nav.Link className="tda-navbar-collapse-item-detail-back" id="tda-navbar-collapse-item-detail-back" href="#">
                                        <div className="tda-navbar-item-with-arrow-back" onClick={handleIsLogin}>
                                            <img src={ArrowRight} alt="arrow"></img>
                                            <p>BACK</p>
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/my-profile"><p>MY PROFILE</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="/garage"><p>MY GARAGE</p></Nav.Link>
                                    <Nav.Link className="tda-navbar-collapse-item-detail" href="#"><p>LOGOUT</p></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Navbar>
        
        <Container className={navPrimary ? "d-none" : "tda-navbar-trigger-mobile"} id={y === 0 ? "" : "tda-navbar-scroll"}>
            <Row className="tda-navbar-trigger-header">
                <Col xs={6} className="tda-navbar-trigger-item tda-navbar-trigger-item-left">
                    <Navbar.Brand href="/" className="tda-navbar-trigger-home" >
                        <img className={slugForIconBlack.includes(location) || y !== 0 ? "icon-black" : ""} src={TdaLogo} alt="arrow"></img>
                    </Navbar.Brand>
                </Col>
                <Col xs={6} className="tda-navbar-trigger-item justify-content-end">
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <img className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-trigger-item-search icon-black" : "tda-navbar-trigger-item-search"} src={IconSearch} alt="l" onClick={clickNavSearchText}></img>
                        <img className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-trigger-item-list icon-black" : "tda-navbar-trigger-item-list"} src={IconList} alt="l" onClick={clickNavPrimary}></img>
                    </Navbar.Toggle>
                </Col>
            </Row>
        </Container>
        
        <Modal
        show={navSearchText}
        onHide={clickNavSearchText}
        dialogClassName="modal-100w tda-modal-search-text"
        aria-labelledby="tda-modal-search-text">
            <Modal.Header className="tda-modal-search-text-header">
                <Modal.Title id="tda-modal-search-text">
                    <img className="btn-close" src={IconClose} alt="x" onClick={clickNavSearchText}></img>
                    <FormField class_extend="col-lg-12 col-xs-12 tda-form-control tda-search" nameField="query" val={textSearch} setHandleValueChange={setHandleValueChange} field_title="" field_type="text" field_place_holder="Search..."/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body 
                className={
                    cars ? "" : "display-none"
                }>
                <Container fluid>
                    <Row>
                        <Col lg={12} xs={12} className="tda-mobile-search">
                            {
                                cars ? 
                                cars.map((car, index) => {
                                    return(
                                        <CarCard src_image={BASE_URL+car.picture} title={car.serie} link_detail={car.slug} key_car={index} key={index}/>
                                    )
                                })
                                :
                                ""
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default NavbarMobile;

